import { Link } from 'react-router-dom';
import { useEffect } from 'react';
interface Option {
  label: string;
  link: string;
}

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  productsOptions: Option[];
  operatorsOptions: Option[];
  communityOptions: Option[];
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  isOpen,
  onClose,
  productsOptions,
  operatorsOptions,
  communityOptions
}) => {
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024 && isOpen) { // 1024px is the 'lg' breakpoint in Tailwind
        onClose();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
      <div className="fixed right-0 top-0 h-full w-64 bg-[#1A1B1E] shadow-lg transform transition-transform duration-300">
        {/* Close Button */}
        <button 
          onClick={onClose}
          className="absolute top-6 right-6 text-[#D6D6DA] hover:text-accent transition-colors duration-200"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        {/* Menu Items */}
        <div className="flex flex-col pt-20 px-6 text-white text-base font-normal pt-mono">
          <MenuSection title="Products" options={productsOptions} />
          <MenuSection title="Operators" options={operatorsOptions} />
          <MenuSection title="Community" options={communityOptions} />
          
          <Link to="" className="py-3 hover:text-accent">
            Contact
          </Link>
          <Link to="" className="py-3 text-accent font-bold">
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
};

const MenuSection: React.FC<{ title: string; options: Option[] }> = ({ title, options }) => (
  <div className="mb-4">
    <h3 className="text-lg font-semibold mb-2 text-white">{title}</h3>
    {options.map((option, index) => (
      <Link
        key={index}
        to={option.link}
        className="block py-2 pl-4 text-[#D6D6DA] transition-colors duration-200 hover:text-accent"
      >
        {option.label}
      </Link>
    ))}
  </div>
);

export default MobileMenu;