import { App } from '../App';
import {
  createBrowserRouter,
} from "react-router-dom";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    // shouldRevalidate: () => true,
    // loader: () => {
    //   if (!localStorage.getItem("session_id")) {
    //     return redirect("/login")
    //   }
    //   return null;
    // },
  },
]);