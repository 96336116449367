import React, { ReactNode } from "react";
//import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { Link } from "react-router-dom";

interface Option {
  label: string;
  link: string;
}

interface OptionsButtonProps {
  className?: string
  options: Option[]
  children: ReactNode
};

export const OptionsButton: React.FC<OptionsButtonProps> = ({ className = "", options, children }) => {
  return (
    <div className="relative group">
      <div className="cursor-pointer">{children}</div>
      <div className="absolute left-1/2 -translate-x-1/2 top-full hidden group-hover:block hover:block">
        <div className="px-3 py-2 bg-[#1D1D1D] shadow-2xl rounded-lg flex flex-col w-max mt-2">
          {options.map((option, index) => (
            <Link to={option.link} key={index} className="cursor-pointer">
              <div className="text-sm font-normal text-primary hover:text-accent px-3 py-2 rounded-[4px]">
                {option.label}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
